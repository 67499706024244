import { createTheme } from "@mui/material/styles";
import { colors } from "@mui/material";

const Theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0 0 2px 0 rgba(0,0,0,0.1)",
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingTop: 30,
          paddingBottom: 30
        },
        title: {
          color: colors.grey[900],
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingTop: 30,
          paddingBottom: 30
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: colors.grey[900],
          fontSize: 16,
          paddingBottom: 1
        }
      }
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          paddingBottom: 30
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: 10
        }
      }
    }
  },
  palette: {
    background: {
      default: "#f9f9f9",
      paper: colors.common.white
    },
    primary: {
      main: "#4359EA",
    },
    secondary: {
      main: colors.grey[200]
    },
    error: {
      main: colors.red[400]
    },
    text: {
      primary: colors.grey[900],
      secondary: colors.grey[600]
    }
  },
  typography: {
    button: {
      fontWeight: "bold"
    },
    h1: {
      fontWeight: 500,
      fontSize: 45,
      letterSpacing: "-0.24px",
      color: "#FFFFFF",
    },
    h2: {
      fontWeight: 500,
      fontSize: 29,
      letterSpacing: "-0.24px",
      color: "#FFFFFF",
    },
    h3: {
      fontWeight: 500,
      fontSize: 24,
      letterSpacing: "-0.06px",
      color: "#FFFFFF",
    },
    h4: {
      fontWeight: 500,
      fontSize: 20,
      letterSpacing: "-0.06px",
      color: "#FFFFFF",
    },
    h5: {
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: "-0.05px",
      color: "#FFFFFF",
    },
    h6: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: "-0.05px",
      color: "#FFFFFF",
    },
    overline: {
      fontWeight: 500,
      color: "#FFFFFF",
    }
  }
});

export default Theme;